import Layout from "../../components/layout"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as css from "../../components/content.module.css"
import SEO from "../../components/seo"

function Lowe() {
  return (
    <Layout>
      <SEO title="LOWE - ZSP Nidek" />
      <h1>LOWE</h1>
      <div className={`${css.justifyText} ${css.longText}`}>
        <p className={css.boldText}>Rozpoczęcie szkoleń dla osób dorosłych</p>
        <p className={css.boldText}>
          Lokalny Ośrodek Wiedzy i Edukacji w Nidku rozpoczyna nabór
          przeznaczony dla{" "}
          <span className={css.underlineText}>osób dorosłych</span>{" "}
          zainteresowanych podniesieniem swoich kwalifikacji poprzez
          uczestnictwo w kursach:
        </p>
        <ul>
          <li>Pożar wewnętrzny – przyczyny, zagrożenia, sposób postępowania</li>
          <li>„Wirtualna konwersacja”</li>
          <li>Moje finanse i transakcje w sieci</li>
          <li>Warsztaty pilotażu drona</li>
          <li>Program Wzmacniania Rodzin</li>
          <li>Szkoła dla Rodziców i Wychowawców</li>
        </ul>
        <p className={css.boldText}>
          Warunkiem udziału w szkoleniu jest złożenie osobiste w Zespole Szkolno
          – Przedszkolnym w Nidku (lub przesłanie mailem) kompletnych dokumentów
          zgłoszeniowych:
        </p>
        <ul>
          <li>Formularz rekrutacyjny</li>
          <li>Oświadczenie dot. ochrony danych osobowych</li>
        </ul>
        <p>Szczegółowa ścieżka rekrutacji dostępna w Regulaminie Rekrutacji</p>
        <p className={css.boldText}>
          Szkolenie przeznaczone jest dla osób dorosłych mieszkańców Gminy
          Wieprz. W przypadku dużej liczby chętnych premiowany będzie udział
          następujących osób:
        </p>
        <ul>
          <li>osoby dorosłe w wieku 45+,</li>
          <li>osoby dorosłe związane z rolnictwem,</li>
          <li>osoby dorosłe nieaktywne zawodowo lub bezrobotne,</li>
          <li>
            osób dorosłe mające niski poziom wykształcenia lub wykształcenie
            wymagające aktualizacji (co najwyżej zasadnicze zawodowe lub
            wykształcenie średnie i policealne),
          </li>
          <li>
            osoby dorosłe podlegające formom pomocy społecznej i wsparcia
            rodziny,
          </li>
          <li>
            osoby dorosłe mające utrudniony dostęp do form edukacji osób
            dorosłych.
          </li>
        </ul>
        <p className={css.boldText}>
          Szkolenia z uwagi na panujące obostrzenia prowadzone będą w grupach 5
          osobowych z zachowaniem W trakcie szkoleń przestrzegane będą
          obowiązujące przepisy sanitarne, w szczególności rozporządzenia Rady
          Ministrów, wytyczne Głównego Inspektora Sanitarnego, wytyczne i
          rozporządzenia Ministra Zdrowia i Ministra Edukacji Narodowej,
          obowiązujące w czasie trwania projektu.
        </p>
        <p>
          Więcej informacji w{" "}
          <span className={`${css.boldText} ${css.underlineText}`}>
            Zespole Szkolno – Przedszkolnym w Nidku
          </span>
          , pod nr. tel. 33/875-58-11 oraz na stronie internetowej{" "}
          <a href="http://zspnidek.pl">http://zspnidek.pl</a>.
        </p>
        <p className={css.centerText}>
          <StaticImage
            src="../../images/lowe_plakat.jpg"
            alt="Plakat LOWE przedstawiający informację o ofercie"
            width={500}
            height={700}
          />
        </p>

        <p className={css.boldText}>
          Lokalny Ośrodek Wiedzy i Edukacji w Gminie Wieprz
        </p>
        <p>Szanowni Państwo,</p>
        <p>
          Miło nam poinformować, że Gmina Wieprz jest jedną z nielicznych gmin w
          Polsce, która otrzymała wsparcie z Unii Europejskiej na utworzenie{" "}
          <span className={css.boldText}>
            Lokalnego Ośrodka Wiedzy i Edukacji
          </span>
          , który działał będzie przy Zespole Szkolno – Przedszkolnym w Nidku.
        </p>
        <p>
          Projekt w ramach którego finansowane będzie funkcjonowanie LOWE
          zakłada wykorzystanie naturalnego potencjału szkoły oraz jej kadry w
          celu aktywizacji osób dorosłych poprzez podejmowanie działań
          edukacyjnych dotyczących rozwoju ich kompetencji osobistych,
          społecznych i zawodowych.
        </p>
        <p className={`${css.boldText} ${css.underlineText}`}>
          Do kogo LOWE kierować będzie swoją ofertę?{" "}
        </p>
        <p>
          Oferta LOWE w Nidku skierowana będzie głównie do dorosłych mieszkańców
          Gminy Wieprz, którzy wpisywać będą się w jedną z poniższych grup osób:
        </p>
        <ul>
          <li>osoby dorosłe w wieku 45+,</li>
          <li>osoby dorosłe związane z rolnictwem,</li>
          <li>osoby dorosłe nieaktywne zawodowo lub bezrobotne,</li>
          <li>
            osób dorosłe mające niski poziom wykształcenia lub wykształcenie
            wymagające aktualizacji (co najwyżej zasadnicze zawodowe lub
            wykształcenie średnie i policealne),
          </li>
          <li>
            osoby dorosłe podlegające formom pomocy społecznej i wsparcia
            rodziny,
          </li>
          <li>
            osoby dorosłe mające utrudniony dostęp do form edukacji osób
            dorosłych.
          </li>
        </ul>
        <p className={`${css.boldText} ${css.underlineText}`}>
          Co będzie miał do zaoferowania LOWE w Nidku?
        </p>
        <p>W projekcie przewidujemy m.in.:</p>
        <ol>
          <li>Organizację klubów zainteresowań,</li>
          <li>
            Przeprowadzanie wykładów, pogadanek, spotkań z „pasjonatami w swoim
            fachu”,
          </li>
          <li>Organizację szkoleń oraz warsztatów,</li>
          <li>
            Wyjazdy integracyjne dla najbardziej aktywnych uczestników zajęć.
          </li>
        </ol>
        <p className={css.boldText}>
          Nie jest to zamknięty katalog oferty LOWE w Nidku!!!!!
        </p>
        <p>
          Tematyka oraz harmonogram zajęć ustalone zostaną do końca października
          br. Jest to czas, w którym opracowana zostanie diagnoza potrzeb w
          zakresie szkolenia osób dorosłych.
        </p>
        <p>
          Wszelkie informacje nt. prac nad diagnozą oraz możliwości składania
          swoich propozycji, zamieszczane będą na stronach internetowych Urzędu
          Gminy w Wieprzu oraz Zespołu Szkolno – Przedszkolnego w Nidku.
        </p>
        <p className={css.boldText}>Gdzie prowadzone będą zajęcia?</p>
        <StaticImage
          src="../../images/pracownia.jpeg"
          alt="Pracownia, w której prowadzone będą zajęcia."
          width={700}
          height={500}
        />
        <p>
          Zajęcia w ramach oferty LOWE prowadzone będą w ZSP w Nidku. Z uwagi
          jednak na ograniczenia związane z pandemią wirusa oferta LOWE
          rozwijana będzie przy współpracy z Domami Kultury w całej gminie
          Wieprz.
        </p>
        <p className={css.boldText}>
          Kiedy rozpoczną się i jak długo organizowane będą zajęcia?
        </p>
        <p>
          Zajęcia rozpoczną się nie wcześniej niż w listopadzie br. i będą
          organizowane w ramach projektu do końca listopada 2021 r.
        </p>
        <p>Gdzie zamieszczane będą informację o ofercie LOWE?</p>
        <p>
          Informacja o ofercie LOWE, możliwości zapisania się na poszczególne
          zajęcia zamieszczana będzie z wyprzedzeniem na stronach internetowych
          Urzędu Gminy w Wieprzu oraz Zespołu Szkolno – Przedszkolnego w Nidku,
          tablicach ogłoszeń oraz w prasie lokalnej.
        </p>
      </div>
      <div className={`${css.boldText} ${css.centerText} ${css.text}`}>
        <p>Lokalny Ośrodek Wiedzy i Edukacji w Nidku</p>
        <p>powstał w ramach umowy grantowej zawartej</p>
        <p>z Fundacją Małopolska Izba Samorządowa,</p>
        <p>
          która w partnerstwie z Powiatem Gorlickim, Gminą Wielka Wieś i Miastem
          Rawa Mazowiecka realizuje projekt „Lokalne Ośrodki Wiedzy
        </p>
        <p>
          i Edukacji na rzecz aktywizacji edukacyjnej osób dorosłych 2”,
          POWR.02.14.00-00-1007/19 dofinansowany z Funduszy Europejskich
        </p>
        <p>
          Kwota uzyskanego grantu przez Gminę Wieprz wynosi 250 000 zł. co
          stanowi 100% kosztów funkcjonowania LOWE.
        </p>
      </div>
    </Layout>
  )
}

export default Lowe
